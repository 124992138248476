import React from 'react'
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              2-3 взрослых <br />
            </td>
            <td>
              от $952 / чел <br />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              4 - 5 взрослых <br />
            </td>
            <td>
              от $766 / чел <br />
            </td>
          </tr>
          <tr>
            <td>
              От 6-и взрослых <br />
            </td>
            <td>от $704 / чел</td>
          </tr>
        </tbody>
      </Table>
      <p>Дополнительная ночь на побережье: от $100/ночь/номер</p>
      <p>
        Скидки для детей : Бесплатно для детей до 3 лет, скидка 75% для детей до
        6 лет скидка 50% для детей до 12 лет.
      </p>
    </div>
  );
}

export default Pricetable