import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes } from "react-icons/fa";
import Pricetable from './Pricetable';
import Booktourcomb from '../../Minicomponents/Booktourcomb';
import Gallary8 from './Gallary8';

const Details = () => {
    return (
      <div className="container d-flex p-0">
        <div className="col-12 my-3 p-1 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">10 дней</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Экскурсия</h5>
                <p className="text-center my-auto">5 дней</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Пляжный Отдых</h5>
                <p className="text-center my-auto">5 дней</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              Отправьтесь в незабываемое путешествие по Шри-Ланке! Первые четыре
              дня вы будете исследовать главные достопримечательности острова:
              посетите приют для слонов в Пиннавала, подниметесь на легендарную
              скалу Сигирия, побываете в Храме Зуба Будды в Канди, прогуляетесь
              по ботаническому саду Перадения, насладитесь видами с вершины
              Амбулувавы, посетите чайные плантации, живописные Нувара-Элия и
              Элла, а также отправитесь на захватывающее джип-сафари в
              национальном парке Яла. Оставшиеся пять дней вы проведете на
              роскошном пляжном курорте, отдыхая у океана и наслаждаясь
              комфортом. Этот тур идеально сочетает активные приключения,
              культурное наследие и расслабляющий отдых.
            </p>
          </div>

          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Маршрут</h2>
            <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">
                  День 1 : Прилет, трансфер в отель, слоны
                </Accordion.Header>
                <Accordion.Body>
                  - Прилет и встреча в аэропорт
                  <br /> - Отель Elephant Bay
                  <br /> - Слоновий питомник Пиннавала
                  <br /> - Ужин
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>День 2 : Сигирия, Дамбулла</Accordion.Header>
                <Accordion.Body>
                  - Завтрак с видом на купание слонов
                  <br /> - Выезд из отеля
                  <br /> - Золотой храм Дамбулла
                  <br /> - Мини экскурсия в деревне - катание на воловьей
                  повозке, на катамаране, кулинарная программа с местными, обед
                  <br /> - Сигирия
                  <br /> - Отель Pelwehera Village Resort
                  <br /> - Ужин
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  День 3 : Канди, Чайные плантации
                </Accordion.Header>
                <Accordion.Body>
                  - Завтрак в отеле
                  <br /> - Выезд из отеля
                  <br /> - Сад аюрведа
                  <br /> - Храм зуба Будды или Королевский ботанический сад
                  <br /> - Обед в ресторане в Канди
                  <br /> - Башня Амбулувава
                  <br /> - Чайная фабрика, плантации, программа дегустации чая
                  <br /> - Отель Ramboda Ralls с видом на водопад
                  <br /> - Ужин
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  День 4 : Нувара-Элия, Поезд, мост
                </Accordion.Header>
                <Accordion.Body>
                  - Завтрак с видом на водопад
                  <br /> - Выезд из отеля
                  <br /> - Мини экскурсия в городе Нувара-Элия
                  <br /> - Поезд
                  <br /> - Девятиарочный мост
                  <br /> - Обед в ресторане в Элле
                  <br /> - Малый пик Адама
                  <br /> - Водопад Равана, кормление обезьян
                  <br /> - Отель Oak Ray Wild
                  <br /> - Ужин
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  День 5 : Нувара-Элия, Поезд, мост
                </Accordion.Header>
                <Accordion.Body>
                  - Джип сафари в национальнои парке Яла
                  <br /> - Завтрак в отеле
                  <br /> - Выезд из отеля
                  <br /> - Черепаховый питомник
                  <br /> - Отель на побережье
                  <br /> - Ужин
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>День 6-9 : Пляжный отдых</Accordion.Header>
                <Accordion.Body>
                  - Пляжный отдых в отеле на побережье. Завтрак и ужин включены.
                  <br />- Во время пляжного отдыха можно организовать экскурсию
                  по наблюдению за китами, речное сафари, экскурсию в форт Галле
                  и другие короткие экскурсии.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>День 10 : Обратный трансфер</Accordion.Header>
                <Accordion.Body>
                  - Завтрак
                  <br /> - Трансфер в отель
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Транспорт со всеми расходами</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Русскоговорящий гид</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Проживание </p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Завтрак + ужин</p>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Авиабилеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Мед страховка</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">
                  Входные билеты (~$110/чел за все входные билеты)
                </p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Обеды ($5-10)</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Личные расходы</p>
              </div>
            </div>
          </div>
          <div className="p-1 col-12 mx-auto deco-text my-3">
            <h2 className="mt-2">Стоимость</h2>
            <div className="col-12 p-0">
              <Pricetable />
            </div>
          </div>
          <div className="p-1 col-12 mx-auto deco-text my-3">
            <h2 className="mt-2">Галерея</h2>
            <div className="col-12 p-0">
              <Gallary8 />
            </div>
          </div>
          <div className="p-1 row col-12 deco-text my-3 mx-auto">
            <div className="p-0 col-12">
              <Booktourcomb tour="Экзотика Шри-Ланки PRO" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details