import React from 'react'
import { Link } from "react-router-dom";
import Footer from '../Maipage.js/Footer';

const Transfermain = () => {
  return (
    <div className="container-fluid p-0">
      <div className="row  br col-12 p-0 mx-auto">
        <div className="tour-hero1 d-flex br p-0">
          <div className="container d-flex mx-auto">
            <div className="row"></div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-2 mb-3">
        <h3 className="text-center mt-5">Трансфер на Шри-Ланке</h3>
        <p className="text-center">Трансферы в/из аэропорта Коломбо</p>
        <div className="row col-12 col-md-8 p-0 mx-auto">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  Маршруть
                </th>   
                <th scope="col" className="text-center">
                  В аэропорт
                </th>
                <th scope="col" className="text-center"> 
                  Из Аэропорта
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Аэропорт - Бентота</td>
                <td>
                  <p className="text-center">$50</p>
                </td>
                <td className="text-center">$50</td>
              </tr>
              <tr>
                <td>Аэропорт - Хиккадува</td>
                <td className="text-center">$55</td>
                <td className="text-center">$55</td>
              </tr>
              <tr>
                <td>Аэропорт - Унаватуна</td>
                <td className="text-center">$60</td>
                <td className="text-center">$60</td>
              </tr>
              <tr>
                <td>Аэропорт - Велигама</td>
                <td className="text-center">$65</td>
                <td className="text-center">$65</td>
              </tr>
              <tr>
                <td>Аэропорт - Мирисса</td>
                <td className="text-center">$70</td>
                <td className="text-center">$70</td>
              </tr>
              <tr>
                <td>Аэропорт - Тангалла</td>
                <td className="text-center">$85</td>
                <td className="text-center">$85</td>
              </tr>
            </tbody>
          </table>
          <p className="p-0">
            <li>
              Цены указаны за автомобиль комфорт класса вместимостью 3 пассажира и 2/3
              чемодана. Все расходы включены (платная дорога , плата за парковку
              и.т.д).
            </li> 
            <li>
              Для бронирования трансфера необходима предоплата 30%. Если вы
              решили отменить бронирование за 48 часа до начала трансфера.
            </li>
            <li>
              Если вы получили счет и внесли предоплату, отмены с нашей стороны
              не будет.
            </li>
            <li>
              Водитель будет ждать вас в аэропорту с табличкой, на которой
              написано ваше имя.   
            </li> 
          </p>
          <div className="col-12 p-0 row mx-auto mt-3 mb-5">
            <Link to="/ordertransfer" className="p-0 no-dec text-dark">
              <div className="col-12  d-flex border-thin">
                <div className="col-12">
                  <h5 className="text-center my-1">Заказать Трансфер</h5>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Transfermain