import React from 'react'

const Hero = () => {
  return (
    <div className="container-fluid hero-background-exoticbudget d-flex">
      <div className="container d-flex m-auto">
        <div className="m-auto d-flex">
          <h1 className="my-auto big-text text-center">
             Бюджетный <br />
           10 дневный тур по Шри Ланке    
            <br />
          </h1>
        </div> 
      </div>
    </div>
  );
}

export default Hero